import React from "react";
import favicon from "../images/favicon.ico";
import Logo from "../images/logo.inline.svg";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

function ListLink(props) {
  const { to, children } = props;
  return (
    <li style={{ display: "inline-block", marginRight: "1rem" }}>
      <Link to={to}>{children}</Link>
    </li>
  );
}

export default function Layout(props) {
  const { children } = props;
  return (
    <div style={{ margin: "3rem auto", height:"90vh", maxWidth: 650, padding: "0 1rem", display: "flex", flexDirection: "column" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Miskin Software</title>
        <link rel="icon" type="image/x-icon" href={favicon}/>
        <link rel="canonical" href="https://miskinsoftware.com" />
      </Helmet>
      <header style={{ marginBottom: "1.5rem" }}>
        <Link to="/" style={{ textShadow: "none", backgroundImage: "none", textDecoration: "none" }}>
          <span>
          <Logo/>
          </span>
        </Link>
        <ul style={{ listStyle: `none`, float: `right` }}>
          <ListLink to="/">Home</ListLink>
          <ListLink to="/about/">About</ListLink>
          <ListLink to="/contact/">Contact</ListLink>
        </ul>
      </header>
      <div style={{width: '100%', display: "grid", 'grid-template-columns': 'auto 150px', flex: "1 0 auto"}}>
        {children}
      </div>
      <div style={{textAlign:"center", fontSize:"0.75em", flexShrink: 0}}>
        Miskin Software Limited is a private company registered in England and Wales.<br/>
        Company Number: 9122656<br/>
        Registered Address: 6 Pennsylvania View, Lansdown, Bath, BA1 9DW
      </div>
    </div>
  );
}
